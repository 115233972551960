<template>
  <div>
    <el-row :gutter="10"
            style="padding-bottom:10px">
      <el-col :lg="12"
              :md="24"
              :sm="24"
              :xl="16">
        <el-button type="primary"
                   @click="addStudent">新增</el-button>
      </el-col>
    </el-row>
    <!-- <el-table border
              :stripe="true"
              highlight-current-row
              :max-height="$store.state.tableHeight + 45"
              :data="tableData"
              style="width: 100%">
      <el-table-column prop="grade_id"
                       align="center"
                       label="编号" />
      <el-table-column prop="grade_name"
                       align="center"
                       label="名称" />
      <el-table-column prop="name"
                       align="center"
                       width="200"
                       label="操作">
        <template slot-scope="{row}">
          <div class="operyion_btn_icon">
            <el-tooltip effect="dark"
                        content="删除"
                        placement="top-start">
              <img src="@/assets/icons/icon-删除@2x.png"
                   @click="delRow(row)"
                   alt="">
            </el-tooltip>

          </div>
        </template>
      </el-table-column>
    </el-table> -->
    <Table ref="TablesRef"
           :tableData="tableData" />
    <Form ref="FormRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import Form from './components/form.vue'
export default {
  data () {
    return {
      page: 1,
      size: 10,
      totalElements: 0,
      tableData: [],
      tableLoading: false,
      showList: [],
      pageName: 'gradeAdmin',
      tableColumns: [
        { prop: 'grade_id', align: 'center', label: '编号', width: '', showToopic: false },
        { prop: 'grade_name', align: 'center', label: '名称', width: '', showToopic: false },
        {
          prop: 'd', align: 'center', label: '操作', width: '', type: 'img', btns: [
            { content: '删除', type: 'del', event: 'delRow' },
          ]
        },
      ]
    }
  },
  components: {
    Form
  },
  mounted () {
    this.setTablesColums()
  },
  methods: {
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      this.$http({
        url: '/api/v2/bureau/grade_lst',
        method: 'get',
        params: {
          page,
          limit
        }
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },

    delRow (row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v2/bureau/grade_del',
          method: 'post',
          data: {
            grade_id: row.grade_id
          }
        })
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        setTimeout(() => {
          this.refresh()
        }, 500);
      })
    },
    addStudent () {
      let form = {
        grade_name: ''
      }
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true
    },
    showForm (row) {
      let form = JSON.parse(JSON.stringify(row))
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
